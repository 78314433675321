<template>
  <v-container fluid fill-height style="background-color: #ffffff;">
    <v-row justify="center">
      <v-col xs="12" sm="8" md="4" lg="3" align="center">
        <v-card class="pa-5" color="transparent" elevation="0">
          <v-row style="height: 600px">
            <v-col class="d-flex align-center justify-center">
              <v-progress-circular
                color="grey lighten-2"
                indeterminate
                size="130"
                width="10"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => {
    return {
      pid: "",
      userDealer: [],
      email: "",
      token: null,
      errorMessages: "",
      password: "",
      message: ""
    };
  },
  computed: {
    host() {
      const url = window.location.host;
      return url;
    },
    adminToken() {
      return this.$store.getters.adminToken;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    programId() {
      return this.$store.getters.programId;
    },
    currentProgram() {
      return this.$store.getters.currentProgram;
    }
  },
  watch: {
    currentUser(value) {
      if (value) {
        this.$store.dispatch("clearCart");
        this.$store.dispatch("getPrimaryMenus", this.currentUser);
        this.$store.dispatch("getSecondaryMenus", this.currentUser);
        this.$store.dispatch("getUserDealer", this.currentUser);
        this.$store.dispatch("getProgressData", this.currentUser);
        this.$router.push("/Home");
        this.$store.dispatch("getPromotionList", this.currentUser);
        this.$store.dispatch("getRewardList", this.currentUser);
        this.$store.dispatch("getOfferList", this.currentUser);
        this.$store.dispatch("loadQuizzes");
      }
    }
  },
  created() {
    this.$store.dispatch("logout");
    this.token = this.$route.params.token;
    this.pid = this.$route.params.id;
    this.$store.dispatch("setProgramId", this.pid);
    const auth = { token: this.token, pid: this.pid };
    this.$store.dispatch("adminAuthFirebase", auth);
  },
  methods: {}
};
</script>

<style></style>
